import React from 'react';
import styles from './FooterBar.module.css'; // Import the CSS module

const FooterBar = () => {
  return (
    <div className={styles.footer}>
    <p>Copyright © 2024 Maze. All Rights Reserved</p>
    <a href="https://x.com/maze_social">X</a>
    <a href="https://www.linkedin.com/company/maze-social/">LinkedIn</a>
    <a href="https://www.facebook.com/getmazesocial/">Facebook</a>
    <a href="https://www.instagram.com/getmazesocial/">Instagram</a>
  </div>

  );
};

export default FooterBar;
