import React, { useEffect, useRef } from 'react';
import styles from './BallAnimation.module.css';

// Constants
const MAZE_WIDTH = 25;
const MAZE_HEIGHT = 19;
const PLAYER_SIZE = 0.7;
const MOVE_SPEED = 0.15;
const MIN_OBSTACLES = 12;
const MAX_OBSTACLES = 18;
const EXPLOSION_FRAMES = 12;
const SPARKLE_PARTICLES = 24;
const SPARKLE_DURATION = 30;

const EXITS = [
  { x: 1, y: 0 },         // Top exit
  { x: MAZE_WIDTH-2, y: MAZE_HEIGHT-1 }, // Bottom exit
  { x: MAZE_WIDTH-1, y: 1 }  // Right exit
];

const OBSTACLE_COLORS = [
  '#4A90E2',  // Blue
  '#9B59B6',  // Purple
  '#2ECC71',  // Green
  '#F1C40F',  // Yellow
  '#E74C3C'   // Red
];

// Character pixel definitions
const CHARACTER_TYPES = {
  CAT: {
    pixels: [
      "00111100",
      "01111110",
      "11011011", // Eyes
      "11111111", // Face
      "11011011", // Whiskers
      "01111110", // Snout
      "00111100", // Mouth
      "00011000"  // Neck
    ],
    color: '#FF6B6B'
  },
  DOG: {
    pixels: [
      "00111100",
      "01111110",
      "11011011", // Eyes
      "11111111", // Face
      "11111111", // Snout
      "01111110", // Mouth with tongue
      "00111100", // Neck
      "00011000"  // Body
    ],
    color: '#8B4513'
  },
  ROBOT: {
    pixels: [
      "11111111",
      "10111101", // Antenna
      "11111111", // Head
      "10011001", // Eyes
      "11111111", // Face
      "10111101", // Panel
      "11000011", // Base
      "01111110"  // Bottom
    ],
    color: '#4682B4'
  },
  HUMAN: {
    pixels: [
      "00111100",
      "01111110",
      "11011011", // Eyes
      "11111111", // Face
      "01111110", // Mouth
      "00111100", // Neck
      "00111100", // Shoulders
      "00011000"  // Body
    ],
    color: '#FFA07A'
  }
};

const BallAnimation = ({ dimmer = 0.9 }) => {
  const canvasRef = useRef(null);
  const playerRef = useRef(null);
  const player2Ref = useRef(null);
  const obstaclesRef = useRef([]);
  const animationRef = useRef(null);
  let ctx;

  function generateMaze() {
    const maze = Array(MAZE_HEIGHT).fill().map(() => Array(MAZE_WIDTH).fill(1));
    
    const carve = (x, y) => {
      maze[y][x] = 0;
      
      const directions = [
        [0, -2], // Up
        [2, 0],  // Right
        [0, 2],  // Down
        [-2, 0]  // Left
      ].sort(() => Math.random() - 0.5);
      
      directions.forEach(([dx, dy]) => {
        const newX = x + dx;
        const newY = y + dy;
        
        if (newX > 0 && newX < MAZE_WIDTH - 1 && 
            newY > 0 && newY < MAZE_HEIGHT - 1 && 
            maze[newY][newX] === 1) {
          maze[y + dy/2][x + dx/2] = 0;
          carve(newX, newY);
        }
      });
    };

    carve(1, 1);
    
    // Ensure paths to exits
    EXITS.forEach(exit => {
      maze[exit.y][exit.x] = 0;
      if (exit.y === 0) maze[1][exit.x] = 0;
      if (exit.y === MAZE_HEIGHT-1) maze[MAZE_HEIGHT-2][exit.x] = 0;
      if (exit.x === MAZE_WIDTH-1) maze[exit.y][MAZE_WIDTH-2] = 0;
    });

    return maze;
  }

  function getRandomPathPosition(maze) {
    const validPositions = [];
    for (let y = 1; y < MAZE_HEIGHT - 1; y++) {
      for (let x = 1; x < MAZE_WIDTH - 1; x++) {
        if (maze[y][x] === 0 && 
            !EXITS.some(exit => exit.x === x && exit.y === y)) {
          validPositions.push({ x, y });
        }
      }
    }
    return validPositions[Math.floor(Math.random() * validPositions.length)];
  }

  function placeObstacles(maze) {
    const obstacles = [];
    const validPositions = [];
    
    for (let y = 1; y < MAZE_HEIGHT - 1; y++) {
      for (let x = 1; x < MAZE_WIDTH - 1; x++) {
        if (maze[y][x] === 0 && 
            !EXITS.some(exit => exit.x === x && exit.y === y)) {
          validPositions.push({ x, y });
        }
      }
    }
    
    const numObstacles = Math.floor(
      Math.random() * (MAX_OBSTACLES - MIN_OBSTACLES + 1)
    ) + MIN_OBSTACLES;
    
    for (let i = 0; i < numObstacles && validPositions.length > 0; i++) {
      const index = Math.floor(Math.random() * validPositions.length);
      const pos = validPositions.splice(index, 1)[0];
      obstacles.push({
        x: pos.x,
        y: pos.y,
        type: 'obstacle',
        color: OBSTACLE_COLORS[Math.floor(Math.random() * OBSTACLE_COLORS.length)]
      });
    }
    
    return obstacles;
  }

  function drawMaze(ctx, maze) {
    const canvas = canvasRef.current;
    const CELL_SIZE = Math.min(
      (window.innerWidth * 0.6) / MAZE_WIDTH,
      (window.innerHeight * 0.6) / MAZE_HEIGHT
    );
    
    const offsetX = (canvas.width - MAZE_WIDTH * CELL_SIZE) / 2;
    const offsetY = (canvas.height - MAZE_HEIGHT * CELL_SIZE) / 2;

    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    for (let y = 0; y < MAZE_HEIGHT; y++) {
      for (let x = 0; x < MAZE_WIDTH; x++) {
        const cellX = offsetX + x * CELL_SIZE;
        const cellY = offsetY + y * CELL_SIZE;

        if (maze[y][x] === 1) {
          ctx.fillStyle = '#333333';
          ctx.fillRect(cellX, cellY, CELL_SIZE, CELL_SIZE);
        }
      }
    }

    // Draw checker flags at exits
    EXITS.forEach(exit => {
      drawCheckerFlag(ctx, 
        offsetX + exit.x * CELL_SIZE, 
        offsetY + exit.y * CELL_SIZE, 
        CELL_SIZE
      );
    });

    return { cellSize: CELL_SIZE, offsetX, offsetY };
  }

  function drawCheckerFlag(ctx, x, y, size) {
    const squareSize = size / 4;
    ctx.fillStyle = '#000000';
    
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        if ((i + j) % 2 === 0) {
          ctx.fillRect(x + j * squareSize, y + i * squareSize, squareSize, squareSize);
        }
      }
    }
  }

  function drawCharacter(ctx, x, y, cellSize, offsetX, offsetY, characterType) {
    const size = cellSize * PLAYER_SIZE;
    const pixelSize = size / 8;
    const character = CHARACTER_TYPES[characterType];
    
    ctx.fillStyle = character.color;
    
    character.pixels.forEach((row, i) => {
      row.split('').forEach((pixel, j) => {
        if (pixel === '1') {
          ctx.fillRect(
            offsetX + x * cellSize + (cellSize - size)/2 + j * pixelSize,
            offsetY + y * cellSize + (cellSize - size)/2 + i * pixelSize,
            pixelSize,
            pixelSize
          );
        }
      });
    });
  }

  function drawObstacle(ctx, obstacle, cellSize, offsetX, offsetY) {
    const radius = cellSize * 0.3;
    const centerX = offsetX + obstacle.x * cellSize + cellSize/2;
    const centerY = offsetY + obstacle.y * cellSize + cellSize/2;
    
    const gradient = ctx.createRadialGradient(
      centerX - radius/3, centerY - radius/3, radius/10,
      centerX, centerY, radius
    );
    gradient.addColorStop(0, '#FFFFFF');
    gradient.addColorStop(0.3, obstacle.color);
    gradient.addColorStop(1, darkenColor(obstacle.color, 30));
    
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
    ctx.fillStyle = gradient;
    ctx.fill();
  }

  function drawStar(ctx, position, cellSize, offsetX, offsetY) {
    const centerX = offsetX + position.x * cellSize + cellSize/2;
    const centerY = offsetY + position.y * cellSize + cellSize/2;
    const radius = cellSize * 0.3;
    
    ctx.beginPath();
    for (let i = 0; i < 5; i++) {
      const angle = (i * 4 * Math.PI) / 5 - Math.PI / 2;
      const x = centerX + Math.cos(angle) * radius;
      const y = centerY + Math.sin(angle) * radius;
      if (i === 0) ctx.moveTo(x, y);
      else ctx.lineTo(x, y);
    }
    ctx.closePath();
    ctx.fillStyle = '#FFD700';
    ctx.fill();
    ctx.strokeStyle = '#FFA500';
    ctx.stroke();
  }

  function drawBomb(ctx, position, cellSize, offsetX, offsetY) {
    const centerX = offsetX + position.x * cellSize + cellSize/2;
    const centerY = offsetY + position.y * cellSize + cellSize/2;
    const radius = cellSize * 0.3;
    
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
    ctx.fillStyle = '#333';
    ctx.fill();
    
    ctx.beginPath();
    ctx.moveTo(centerX, centerY - radius);
    ctx.quadraticCurveTo(
      centerX + radius/2, centerY - radius * 1.5,
      centerX + radius, centerY - radius
    );
    ctx.strokeStyle = '#FFA500';
    ctx.lineWidth = 3;
    ctx.stroke();
  }

  function drawExplosion(ctx, x, y, cellSize, offsetX, offsetY, frame) {
    const size = cellSize * 1.5;
    const centerX = offsetX + x * cellSize + cellSize/2;
    const centerY = offsetY + y * cellSize + cellSize/2;
    
    const gradient = ctx.createRadialGradient(
      centerX, centerY, 0,
      centerX, centerY, size * (frame / EXPLOSION_FRAMES)
    );
    
    gradient.addColorStop(0, 'rgba(255, 200, 0, 0.8)');
    gradient.addColorStop(0.5, 'rgba(255, 100, 0, 0.5)');
    gradient.addColorStop(1, 'rgba(255, 0, 0, 0)');
    
    ctx.beginPath();
    ctx.arc(centerX, centerY, size * (frame / EXPLOSION_FRAMES), 0, Math.PI * 2);
    ctx.fillStyle = gradient;
    ctx.fill();
  }

  function drawSparkle(ctx, x, y, cellSize, offsetX, offsetY, frame) {
    const centerX = offsetX + x * cellSize + cellSize / 2;
    const centerY = offsetY + y * cellSize + cellSize / 2;
    const maxRadius = cellSize * 2; // Increased radius for longer lines
    const numParticles = SPARKLE_PARTICLES;
    const angleStep = (Math.PI * 2) / numParticles;
    const progress = frame / SPARKLE_DURATION;

    ctx.lineWidth = 3 * (1 - progress); // Lines get thinner as they fade

    for (let i = 0; i < numParticles; i++) {
      const angle = i * angleStep + (frame * 0.1); // Slower rotation
      const radius = maxRadius * progress;
      
      // Calculate start and end points for lines
      const innerRadius = radius * 0.2; // Start point closer to center
      const startX = centerX + Math.cos(angle) * innerRadius;
      const startY = centerY + Math.sin(angle) * innerRadius;
      const endX = centerX + Math.cos(angle) * radius;
      const endY = centerY + Math.sin(angle) * radius;

      // Create gradient for the line
      const gradient = ctx.createLinearGradient(startX, startY, endX, endY);
      gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
      gradient.addColorStop(0.4, 'rgba(255, 220, 0, 0.8)');
      gradient.addColorStop(1, 'rgba(255, 100, 0, 0)');

      // Draw the line
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.strokeStyle = gradient;
      ctx.stroke();

      // Add small glowing dots at the end of each line
      ctx.beginPath();
      ctx.arc(endX, endY, 2 * (1 - progress), 0, Math.PI * 2);
      ctx.fillStyle = 'rgba(255, 220, 0, ' + (1 - progress) + ')';
      ctx.fill();
    }
  }

  function darkenColor(color, percent) {
    const num = parseInt(color.replace('#', ''), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) - amt;
    const G = (num >> 8 & 0x00FF) - amt;
    const B = (num & 0x0000FF) - amt;
    return '#' + (0x1000000 + (R<0?0:R)*0x10000 + (G<0?0:G)*0x100 + (B<0?0:B)).toString(16).slice(1);
  }

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  function findRandomPath(maze, startX, startY, exit) {
    const visited = new Set();
    const path = [];
    
    const dfs = (x, y) => {
      if (x === exit.x && y === exit.y) {
        return true;
      }
      
      const directions = [
        [0, -1], [1, 0], [0, 1], [-1, 0]
      ].sort(() => Math.random() - 0.5);
      
      for (const [dx, dy] of directions) {
        const newX = x + dx;
        const newY = y + dy;
        const key = `${newX},${newY}`;
        
        if (newX >= 0 && newX < MAZE_WIDTH && 
            newY >= 0 && newY < MAZE_HEIGHT && 
            maze[newY][newX] !== 1 && 
            !visited.has(key)) {
          visited.add(key);
          path.push([newX, newY]);
          if (dfs(newX, newY)) return true;
          path.pop();
        }
      }
      return false;
    };
    
    visited.add(`${startX},${startY}`);
    path.push([startX, startY]);
    dfs(startX, startY);
    return path;
  }

  function checkRevealedObstacles(obstacles) {
    const totalObstacles = obstacles.length;
    const revealedCount = obstacles.filter(obs => 
      obs.type === 'star' || obs.type === 'bomb'
    ).length;
    
    return (revealedCount / totalObstacles) > 0.5;
  }

  function generateFreshObstacles(maze) {
    const numObstacles = Math.floor(
      Math.random() * (MAX_OBSTACLES - MIN_OBSTACLES + 1)
    ) + MIN_OBSTACLES;
    
    const validPositions = [];
    for (let y = 1; y < MAZE_HEIGHT - 1; y++) {
      for (let x = 1; x < MAZE_WIDTH - 1; x++) {
        if (maze[y][x] === 0 && 
            !EXITS.some(exit => exit.x === x && exit.y === y)) {
          validPositions.push({ x, y });
        }
      }
    }
    
    const newObstacles = [];
    for (let i = 0; i < numObstacles && validPositions.length > 0; i++) {
      const index = Math.floor(Math.random() * validPositions.length);
      const pos = validPositions.splice(index, 1)[0];
      newObstacles.push({
        x: pos.x,
        y: pos.y,
        type: 'obstacle',
        color: OBSTACLE_COLORS[Math.floor(Math.random() * OBSTACLE_COLORS.length)]
      });
    }
    
    return newObstacles;
  }

  function getRandomCharacterType() {
    const types = Object.keys(CHARACTER_TYPES);
    return types[Math.floor(Math.random() * types.length)];
  }

  async function animate(ctx, maze, cellSize, offsetX, offsetY) {
    const player = playerRef.current;
    const player2 = player2Ref.current;
    const obstacles = obstaclesRef.current;
    
    const reachedExit = EXITS.some(exit => 
      Math.abs(exit.x - player.x) < 0.1 && 
      Math.abs(exit.y - player.y) < 0.1
    );

    if (reachedExit) {
      // Fade out character and current obstacles
      for (let opacity = 1; opacity > 0; opacity -= 0.1) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        drawMaze(ctx, maze);
        
        // Fade out existing obstacles
        ctx.globalAlpha = opacity;
        obstacles.forEach(obstacle => {
          if (obstacle.type === 'obstacle') {
            drawObstacle(ctx, obstacle, cellSize, offsetX, offsetY);
          } else if (obstacle.type === 'star') {
            drawStar(ctx, obstacle, cellSize, offsetX, offsetY);
          } else if (obstacle.type === 'bomb') {
            drawBomb(ctx, obstacle, cellSize, offsetX, offsetY);
          }
        });
        
        // Fade out character
        drawCharacter(ctx, player.x, player.y, cellSize, offsetX, offsetY, player.characterType);
        ctx.globalAlpha = 1;
        
        await delay(50);
      }
      
      await delay(500); // Short pause
      
      // Check if we need to regenerate obstacles
      if (checkRevealedObstacles(obstacles)) {
        // Generate and fade in new obstacles
        const newObstacles = generateFreshObstacles(maze);
        obstaclesRef.current = newObstacles;
        
        // Fade in new obstacles
        for (let opacity = 0; opacity < 1; opacity += 0.1) {
          ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          drawMaze(ctx, maze);
          
          ctx.globalAlpha = opacity;
          newObstacles.forEach(obstacle => {
            drawObstacle(ctx, obstacle, cellSize, offsetX, offsetY);
          });
          ctx.globalAlpha = 1;
          
          await delay(50);
        }
      }
      
      // Create new character at random position
      const newStartPos = getRandomPathPosition(maze);
      playerRef.current = {
        x: newStartPos.x,
        y: newStartPos.y,
        targetX: newStartPos.x,
        targetY: newStartPos.y,
        path: [],
        removed: false,
        characterType: getRandomCharacterType()
      };

      requestAnimationFrame(() => animate(ctx, maze, cellSize, offsetX, offsetY));
      return;
    }

    if (player.path.length === 0) {
      const randomExit = EXITS[Math.floor(Math.random() * EXITS.length)];
      player.path = findRandomPath(maze, Math.floor(player.x), Math.floor(player.y), randomExit);
      if (player.path.length > 0) {
        const [nextX, nextY] = player.path[1] || player.path[0];
        player.targetX = nextX;
        player.targetY = nextY;
      }
    }

    const dx = player.targetX - player.x;
    const dy = player.targetY - player.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    
    if (distance > 0.1) {
      player.x += (dx / distance) * MOVE_SPEED;
      player.y += (dy / distance) * MOVE_SPEED;
      await delay(50);
    } else {
      player.x = player.targetX;
      player.y = player.targetY;
      player.path.shift();
      if (player.path.length > 0) {
        [player.targetX, player.targetY] = player.path[0];
        await delay(200);
      }
    }

    const collision = obstacles.find(obs => 
      Math.abs(obs.x - player.x) < 0.5 && 
      Math.abs(obs.y - player.y) < 0.5 &&
      obs.type === 'obstacle'
    );

    if (collision) {
      for (let frame = 0; frame < EXPLOSION_FRAMES; frame++) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        drawMaze(ctx, maze);
        
        obstacles.forEach(obstacle => {
          if (obstacle !== collision) {
            if (obstacle.type === 'obstacle') {
              drawObstacle(ctx, obstacle, cellSize, offsetX, offsetY);
            } else if (obstacle.type === 'star') {
              drawStar(ctx, obstacle, cellSize, offsetX, offsetY);
            } else if (obstacle.type === 'bomb') {
              drawBomb(ctx, obstacle, cellSize, offsetX, offsetY);
            }
          }
        });
        
        drawExplosion(ctx, collision.x, collision.y, cellSize, offsetX, offsetY, frame);
        drawSparkle(ctx, collision.x, collision.y, cellSize, offsetX, offsetY, frame);
        drawCharacter(ctx, player.x, player.y, cellSize, offsetX, offsetY, player.characterType);
        
        await delay(1000 / 60);
      }
      
      collision.type = Math.random() < 0.5 ? 'star' : 'bomb';
      
      const glowFrames = 10;
      for (let frame = 0; frame < glowFrames; frame++) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        drawMaze(ctx, maze);
        
        obstacles.forEach(obstacle => {
          if (obstacle !== collision) {
            if (obstacle.type === 'obstacle') {
              drawObstacle(ctx, obstacle, cellSize, offsetX, offsetY);
            } else if (obstacle.type === 'star') {
              drawStar(ctx, obstacle, cellSize, offsetX, offsetY);
            } else if (obstacle.type === 'bomb') {
              drawBomb(ctx, obstacle, cellSize, offsetX, offsetY);
            }
          }
        });
        
        ctx.save();
        ctx.shadowColor = collision.type === 'star' ? '#FFD700' : '#FF4444';
        ctx.shadowBlur = 20 * (1 - frame/glowFrames);
        if (collision.type === 'star') {
          drawStar(ctx, collision, cellSize, offsetX, offsetY);
        } else {
          drawBomb(ctx, collision, cellSize, offsetX, offsetY);
        }
        ctx.restore();
        
        drawCharacter(ctx, player.x, player.y, cellSize, offsetX, offsetY, player.characterType);
        
        await delay(50);
      }
      
      await delay(200);
    }

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    drawMaze(ctx, maze);
    
    obstacles.forEach(obstacle => {
      if (obstacle.type === 'obstacle') {
        drawObstacle(ctx, obstacle, cellSize, offsetX, offsetY);
      } else if (obstacle.type === 'star') {
        drawStar(ctx, obstacle, cellSize, offsetX, offsetY);
      } else if (obstacle.type === 'bomb') {
        drawBomb(ctx, obstacle, cellSize, offsetX, offsetY);
      }
    });

    drawCharacter(ctx, player.x, player.y, cellSize, offsetX, offsetY, player.characterType);
    
    requestAnimationFrame(() => animate(ctx, maze, cellSize, offsetX, offsetY));
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    ctx = canvas.getContext('2d');
    
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    
    const maze = generateMaze();
    const startPos = getRandomPathPosition(maze);
    const startPos2 = getRandomPathPosition(maze);
    
    playerRef.current = {
      x: startPos.x,
      y: startPos.y,
      targetX: startPos.x,
      targetY: startPos.y,
      path: [],
      removed: false,
      characterType: getRandomCharacterType()
    };

    player2Ref.current = {
      x: startPos2.x,
      y: startPos2.y,
      targetX: startPos2.x,
      targetY: startPos2.y,
      path: [],
      removed: false,
      characterType: getRandomCharacterType()
    };
    
    obstaclesRef.current = placeObstacles(maze);
    
    const cellSize = Math.min(
      (window.innerWidth * 0.6) / MAZE_WIDTH,
      (window.innerHeight * 0.6) / MAZE_HEIGHT
    );
    const offsetX = (canvas.width - MAZE_WIDTH * cellSize) / 2;
    const offsetY = (canvas.height - MAZE_HEIGHT * cellSize) / 2;
    
    animate(ctx, maze, cellSize, offsetX, offsetY);
    
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className={styles.canvas}
      style={{
        opacity: dimmer,
        position: 'absolute',
        top: 0,
        left: 0,
         zIndex: -1,
        width: '100%',
        height: '100%',
      }}
    />
  );
};

export default BallAnimation; 