import React, { useState } from 'react';
import HeaderBar from '../common/HeaderBar';
import styles from './DeveloperContact.module.css';
import { appClient } from '../api/client';
import FooterBar from '../common/FooterBar';

const DeveloperContact = () => {
  const [formData, setFormData] = useState({
    name: '',    // Required
    email: '',     // Required
    company: '',      // Required
    website: '',    // Required
    description: ''   // Optional
  });
  const [isSent, setIsSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      const response = await appClient.post('/developer-query/', formDataToSend);
      if (response.status === 200 || response.status === 201) {
        setIsSent(true);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  // Menu items for HeaderBar
  const menuItems = [
  ];

  return (
    <div className={styles.pageContainer}>
      <HeaderBar menuItems={menuItems} />
      
      <div className={styles.container}>
        <div className={styles.infoSection}>
          <h1>Developer Enquiry</h1>
          <div className={styles.infoContent}>
            <p>We are aiming to bring seamless multiplayer gaming experiences, 
               real-time engagement, and dynamic content delivery. Our mission is to create dynamic, 
               engaging worlds where players can explore, compete, and connect, bringing their gaming 
               passions to life in new and exciting ways. </p> 
               
               <p>If you're interested in partnering with us to shape the future of gaming, we'd love to hear from you.</p>
          </div>
        </div>

        <div className={styles.formSection}>
          {!isSent ? (
            <form onSubmit={handleSubmit}>
              <div className={styles.formRow}>
                <div className={styles.inputGroup}>
                  <label htmlFor="name">Name <span className={styles.required}>*</span></label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className={styles.inputGroup}>
                  <label htmlFor="email">E-Mail <span className={styles.required}>*</span></label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className={styles.inputGroup}>
                <label htmlFor="company">Company <span className={styles.required}>*</span></label>
                <input
                  id="company"
                  name="company"
                  type="text"
                  value={formData.company}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className={styles.inputGroup}>
                <label htmlFor="website">Website <span className={styles.required}>*</span></label>
                <input
                  id="website"
                  name="website"
                  type="text"
                  value={formData.website}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className={styles.inputGroup}>
                <label htmlFor="description">We want to hear from you!</label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  rows={4}
                />
              </div>

              <button type="submit" className={styles.submitButton}>
                Submit
              </button>
            </form>
          ) : (
            <div className={styles.successMessage}>
              <h2>Thank you for your interest!</h2>
              <p>We'll get back to you soon.</p>
            </div>
          )}
        </div>
      </div>
      <FooterBar/>
    </div>
  );
};

export default DeveloperContact; 