import React, { useState } from 'react';
import styles from '../common/ContactModal.module.css';
import { appClient } from '../api/client';

const ContactModal = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',           // Required
    email: '',         // Required
    company: '',       // Optional
    website: '',       // Optional
    subject: '',       // Required
    description: ''    // Required
  });
  const [isSent, setIsSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    
    // Add all form fields to FormData
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      const response = await appClient.post('/query/', formDataToSend);

      if (response.status === 200 || response.status === 201) {
        console.log('Success contact modal:', response.data);
        setIsSent(true);
      } else {
        console.error('Failed to send query');
      }
    } catch (error) {
      console.error('Error:', error);
    }  
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        {!isSent ? (
          <form onSubmit={handleSubmit}>
            <h2>Contact Us</h2>
            
            <div className={styles.inputGroup}>
              <label htmlFor="name">Name *</label>
              <input
                id="name"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Enter your name"
              />
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="email">Email *</label>
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Enter your email address"
              />
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="company">Company (Optional)</label>
              <input
                id="company"
                name="company"
                type="text"
                value={formData.company}
                onChange={handleChange}
                placeholder="Enter your company name"
              />
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="website">Website (Optional)</label>
              <input
                id="website"
                name="website"
                type="url"
                value={formData.website}
                onChange={handleChange}
                placeholder="Enter your website URL"
              />
            </div>
            
            <div className={styles.inputGroup}>
              <label htmlFor="subject">Subject *</label>
              <input
                id="subject"
                name="subject"
                type="text"
                value={formData.subject}
                onChange={handleChange}
                required
                placeholder="Enter the subject of your query"
              />
            </div>
            
            <div className={styles.inputGroup}>
              <label htmlFor="description">Query *</label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                placeholder="Describe your issue or question"
              ></textarea>
            </div>
            
            <div className={styles.buttonGroup}>
              <button type="submit">Send</button>
              <button type="button" onClick={onClose} className={styles.cancelButton}>Cancel</button>
            </div>
          </form>
        ) : (
          <div className={styles.successMessage}>
            <h2>Query Sent Successfully</h2>
            <p>Thank you for contacting us. We'll get back to you soon.</p>
            <button onClick={onClose}>Close</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactModal;