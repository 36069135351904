import React, { useState } from 'react';
import HeaderBar from '../common/HeaderBar';
import styles from './HomePage.module.css';
import ContactModal from '../common/ContactModal';
import FooterBar from '../common/FooterBar';
import { appClient } from '../api/client';
import BallAnimation from '../game/BallAnimation';

const HomePage = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLinkClick = () => {
    setIsModalOpen(true);
  };

  const handleNotifyMe = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('email', email);
    
    try {
      const response = await appClient.post('/notify/', formData);
      console.log('Response $$$:', response);
      if (response.status === 200 || response.status === 201) {
        console.log('Success notify me:', response.data);
        setIsSubscribed(true);
      } else {
        console.error('Failed to send query');
      }

    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  const menuItems = [
    { type: 'link', label: 'Developers', href: '/dev-contact' },
    { type: 'button', label: 'Contact Us', className: 'contactButton', onClick: () => handleLinkClick() },
  ];

  return (
    <div className={styles.homePage}>
      <BallAnimation dimmer={0.15} />
      <HeaderBar menuItems={menuItems} />
      <div className={styles.container}>
        <div className={styles.mainContent}>
          {isSubscribed 
          ?
          <div className={styles.header}>
            <h1>Welcome to the Squad!</h1>
            <p>Get ready for an epic gaming journey! We'll notify you as soon as our platform launches.</p>
            <div className={styles.features}>
              <div>🎮 Game Store</div>
              <div>🤝 Community</div>
              <div>🏆 Tournaments</div>
            </div>
          </div> 
          : 
          <>
            <div className={styles.header}>
              <h1>Level Up Your Gaming Experience</h1>
              <p>
                Your ultimate gaming destination is about to launch! Join a community of passionate gamers, 
                discover amazing titles, and connect with players worldwide.
              </p>
              <div className={styles.features}>
                <div className={styles.featureItem}>
                  <h3>🎮 Game Store</h3>
                  <p>Curated collection of top titles</p>
                </div>
                <div className={styles.featureItem}>
                  <h3>🤝 Social Hub</h3>
                  <p>Connect with fellow gamers</p>
                </div>
                <div className={styles.featureItem}>
                  <h3>🏆 Tournaments</h3>
                  <p>Compete and win rewards</p>
                </div>
                <div className={`${styles.featureItem} ${styles.rewardProgram}`}>
                  <div className={styles.rewardTitle}>
                    <span className={styles.sparkle}>✨</span>
                    <h3 className={styles.rewardHeading}>
                      <span className={styles.gradientText}>Reward Programs</span>
                    </h3>
                    <span className={styles.sparkle}>✨</span>
                  </div>
                  <p>Earn points and unlock exclusive perks</p>
                  <div className={styles.rewardIconsCircle}>
                    <span>🎁</span>
                    <span>🏆</span>
                    <span>⭐</span>
                    <span>💫</span>
                    <span>🌟</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.formContainer}>
              <h2>Be the First to Join</h2>
              <p>Get exclusive early access and special launch offers!</p>
              <form onSubmit={handleNotifyMe}>
                <div className={styles.inputGroup}>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email address"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button type="submit" className={styles.notifyButton}>
                    Join the Waitlist
                  </button>
                </div>
              </form>
            </div>
          </>
          }
        </div>
        <FooterBar/>
      </div>
      {isModalOpen && <ContactModal onClose={() => setIsModalOpen(false)} />}
    </div>
  );
};

export default HomePage;
