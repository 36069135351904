import React, { useState } from 'react';
import HeaderBar from '../common/HeaderBar';
import FooterBar from '../common/FooterBar';
import styles from './AdminPage.module.css';
import { appClient } from '../api/client';

const AdminPage = () => {
  const [code, setCode] = useState('');
  const [queries, setQueries] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [error, setError] = useState('');
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [unnotifiedCount, setUnnotifiedCount] = useState(0);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');


    
    try {
      const response = await appClient.get('/query/', {
        params: { code }
      });
      
      if (response.status === 200) {
        console.log('Response:', response.data);
        setQueries(sortQueriesByDate(response.data.items));
        setIsAuthorized(true);
        
        const countResponse = await appClient.get('/subscriber-count/');
        setSubscriberCount(countResponse.data.count);
        setUnnotifiedCount(countResponse.data.open);
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Invalid access code');
      setTimeout(() => setError(''), 3000);
    }
  };

  const truncateText = (text, maxLength = 30) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const handleRowClick = async (query) => {
    setSelectedQuery(query);
    const formData = new FormData();
    formData.append('id', query.id);
    try {
      // Send POST request to mark as read
      await appClient.post('/read/', formData);
      
      // Update local state
      setQueries(prevQueries => prevQueries.map(q => 
        q.id === query.id ? { ...q, read: true } : q
      ));
    } catch (error) {
      console.error('Error marking query as read:', error);
    }
  };

  const handleReply = (email) => {
    window.location.href = `mailto:${email}?subject=Re: ${selectedQuery.subject}`;
  };

  const menuItems = [

  ];

  const fetchData = async () => {
    try {
      const [queriesResponse, countResponse] = await Promise.all([
        appClient.get('/query/'),
        appClient.get('/subscriber-count/')
      ]);
      
      console.log('Queries Response:', queriesResponse.data);
      setQueries(sortQueriesByDate(queriesResponse.data.items || []));
      setSubscriberCount(countResponse.data.count);
      setUnnotifiedCount(countResponse.data.open);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };

  const sortQueriesByDate = (queries) => {
    return [...queries].sort((a, b) => 
      new Date(b.created_at) - new Date(a.created_at)
    );
  };

  return (
    <div className={styles.adminPage}>
      <HeaderBar menuItems={menuItems} />
      <div className={`${styles.container} ${isAuthorized ? styles.showingQueries : ''}`}>
        {!isAuthorized ? (
          <>
            <div className={styles.header}>
              <h1>Admin Access</h1>
            </div>

            <div className={styles.formContainer}>
              <form onSubmit={handleSubmit}>
                <div className={styles.inputGroup}>
                  <input
                    type="password"
                    placeholder="Enter access code"
                    required
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <button type="submit" className={styles.submitButton}>
                    Get Admin Page
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          <>
            <div className={styles.statsBar}>
              <div className={styles.statsHeader}>
                <h2>Dashboard</h2>
                <button 
                  onClick={fetchData} 
                  className={styles.refreshButton}
                >
                  Refresh
                </button>
              </div>
              <div className={styles.statItem}>
                <span className={styles.statLabel}>Total Subscribers:</span>
                <span className={styles.statValue}>{subscriberCount}</span>
              </div>
              <div className={styles.statItem}>
                <span className={styles.statLabel}>Unnotified Subscribers:</span>
                <span className={styles.statValue}>{unnotifiedCount}</span>
              </div>
            </div>
            <div className={styles.queriesContainer}>
              <h2>Submitted Queries</h2>
              <table className={styles.queriesTable}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Company</th>
                    <th>Website</th>
                    <th>Subject</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {queries.map((query, index) => (
                    <tr 
                      key={index} 
                      onClick={() => handleRowClick(query)}
                      className={`${styles.tableRow} ${query.read ? styles.readRow : styles.unreadRow}`}
                    >
                      <td>{new Date(query.created_at).toLocaleString('en-US', {
                        dateStyle: 'short',
                        timeStyle: 'short'
                      })}</td>
                      <td>{query.name}</td>
                      <td>{query.email}</td>
                      <td>{query.company || '-'}</td>
                      <td>
                        {query.website ? (
                          <a 
                            href={query.website.startsWith('http') ? query.website : `https://${query.website}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                            className={styles.websiteLink}
                          >
                            {query.website}
                          </a>
                        ) : '-'}
                      </td>
                      <td>{truncateText(query.subject)}</td>
                      <td>{truncateText(query.description)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {selectedQuery && (
          <div className={styles.modalOverlay} onClick={() => setSelectedQuery(null)}>
            <div className={styles.detailModal} onClick={e => e.stopPropagation()}>
              <h3>Query Details</h3>
              <div className={styles.modalContent}>
                <p><strong>Date:</strong> {new Date(selectedQuery.created_at).toLocaleString()}</p>
                <p><strong>Name:</strong> {selectedQuery.name}</p>
                <p><strong>Email:</strong> {selectedQuery.email}</p>
                <p><strong>Company:</strong> {selectedQuery.company || '-'}</p>
                <p><strong>Website:</strong> {selectedQuery.website || '-'}</p>
                <p><strong>Subject:</strong> {selectedQuery.subject}</p>
                <p><strong>Description:</strong> {selectedQuery.description}</p>
                <div className={styles.modalButtons}>
                  <button 
                    className={styles.replyButton}
                    onClick={() => handleReply(selectedQuery.email)}
                  >
                    Reply
                  </button>
                  <button 
                    className={styles.closeButton}
                    onClick={() => setSelectedQuery(null)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {error && (
          <div className={styles.errorPopup}>
            {error}
          </div>
        )}

        <FooterBar />
      </div>
    </div>
  );
};

export default AdminPage;
