import React, { useState, useEffect, useCallback } from 'react';
import styles from './MazeGame.module.css';

const MazeGame = () => {
  const MAZE_HEIGHT = 17;
  const MAZE_WIDTH = 19;
  const INITIAL_SCORE = 300;
  const STAR_POINTS = 200;
  const POISON_PENALTY = STAR_POINTS / 2;
  const TOTAL_STARS = 6;
  const TOTAL_POISONS = TOTAL_STARS * 2;
  const TIMER_DURATION = 60;
  
  const [maze, setMaze] = useState([]);
  const [playerPos, setPlayerPos] = useState({ x: 1, y: 1 });
  const [items, setItems] = useState([]); // Combined stars and poison pills
  const [revealedItems, setRevealedItems] = useState([]); // Track revealed items
  const [score, setScore] = useState(INITIAL_SCORE);
  const [gameWon, setGameWon] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [message, setMessage] = useState('Let\'s go!');
  const [showCompletionDialog, setShowCompletionDialog] = useState(false);
  const [timeLeft, setTimeLeft] = useState(TIMER_DURATION);

  // Generate maze using recursive backtracking
  const generateMaze = () => {
    // Initialize maze with walls
    const newMaze = Array(MAZE_HEIGHT).fill().map(() => Array(MAZE_WIDTH).fill(1));
    
    const carve = (x, y) => {
      newMaze[y][x] = 0;
      
      const directions = [
        [0, -2], // Up
        [2, 0],  // Right
        [0, 2],  // Down
        [-2, 0]  // Left
      ].sort(() => Math.random() - 0.5);
      
      directions.forEach(([dx, dy]) => {
        const newX = x + dx;
        const newY = y + dy;
        
        if (newX > 0 && newX < MAZE_WIDTH - 1 && 
            newY > 0 && newY < MAZE_HEIGHT - 1 && 
            newMaze[newY][newX] === 1) {
          newMaze[y + dy/2][x + dx/2] = 0;
          carve(newX, newY);
        }
      });
    };

    // Start carving from top-left (1,1)
    carve(1, 1);
    
    // Set start position (already carved)
    newMaze[1][1] = 0;
    
    // Set goal position at bottom-right
    newMaze[MAZE_HEIGHT - 2][MAZE_WIDTH - 2] = 2;
    
    // Ensure path to goal
    newMaze[MAZE_HEIGHT - 2][MAZE_WIDTH - 3] = 0; // Path to goal
    newMaze[MAZE_HEIGHT - 3][MAZE_WIDTH - 2] = 0; // Path to goal

    return newMaze;
  };

  // Place random items (stars and poison pills)
  const placeItems = (maze) => {
    const newItems = [];
    
    // Keep trying until we place all items
    while (newItems.length < (TOTAL_STARS + TOTAL_POISONS)) {
      const x = Math.floor(Math.random() * (MAZE_WIDTH-2)) + 1;
      const y = Math.floor(Math.random() * (MAZE_HEIGHT-2)) + 1;
      
      if (maze[y][x] === 0 && // Is path
          !(x === 1 && y === 1) && // Not start
          !(x === MAZE_WIDTH-2 && y === MAZE_HEIGHT-2) && // Not goal
          !newItems.some(item => item.x === x && item.y === y)) { // Not already placed
        
        newItems.push({
          x,
          y,
          type: newItems.length < TOTAL_STARS ? 'star' : 'poison',
          revealed: false
        });
      }
    }
    
    return newItems;
  };

  // Initialize game
  useEffect(() => {
    const newMaze = generateMaze();
    const newItems = placeItems(newMaze);
    setMaze(newMaze);
    setItems(newItems);
    setPlayerPos({ x: 1, y: 1 });
    setScore(INITIAL_SCORE);
    setGameWon(false);
    setGameOver(false);
    setMessage('');
    setRevealedItems([]);
    setTimeLeft(TIMER_DURATION);
  }, []);

  const handleItem = (action, item) => {
    if (action === 'P') { // Pick
      if (item.type === 'star') {
        setScore(prev => prev + STAR_POINTS);
        setMessage('Found a star! +200 points');
      } else {
        setScore(prev => {
          const newScore = prev - POISON_PENALTY;
          if (newScore <= 0) {
            setGameOver(true);
            setMessage('Game Over! Score reached zero');
            setShowCompletionDialog(true);
          }
          return Math.max(0, newScore);
        });
        setMessage('Ouch! Poison pill! -100 points');
      }
    } else {
      setMessage(`Skipped ${item.type === 'star' ? 'a star!' : 'a poison pill!'}`);
    }
    
    // Reveal the item
    setRevealedItems(prev => [...prev, { x: item.x, y: item.y }]);
  };

  // Handle keyboard movement
  const handleMove = useCallback((dx, dy) => {
    if (gameOver) return;
    
    setPlayerPos(prev => {
      const newX = prev.x + dx;
      const newY = prev.y + dy;
      
      // Check if move is valid
      if (newX < 0 || newX >= MAZE_WIDTH || 
          newY < 0 || newY >= MAZE_HEIGHT || 
          maze[newY][newX] === 1) {
        return prev;
      }

      // Check for win condition
      if (maze[newY][newX] === 2) {
        if (score >= 300) {
          setGameWon(true);
          setMessage('Congratulations! You made it with enough points!');
        } else {
          setGameOver(true);
          setMessage('Score too low for leaderboard! Need at least 300 points.');
        }
        setShowCompletionDialog(true);
      }

      return { x: newX, y: newY };
    });
  }, [maze, score, gameOver]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      const currentItem = items.find(item => 
        item.x === playerPos.x && 
        item.y === playerPos.y && 
        !revealedItems.some(rev => rev.x === item.x && rev.y === item.y)
      );

      if (currentItem) {
        if (e.key === ' ') {
          handleItem('P', currentItem);
          return;
        } else if (e.key.toUpperCase() === 'S') {
          handleItem('S', currentItem);
          return;
        }
      }

      // Handle movement
      switch(e.key) {
        case 'ArrowUp':
          handleMove(0, -1);
          break;
        case 'ArrowRight':
          handleMove(1, 0);
          break;
        case 'ArrowDown':
          handleMove(0, 1);
          break;
        case 'ArrowLeft':
          handleMove(-1, 0);
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleMove, playerPos, items, revealedItems]);

  // Add this effect to check player position and update message
  useEffect(() => {
    const currentItem = items.find(item => 
      item.x === playerPos.x && 
      item.y === playerPos.y && 
      !revealedItems.some(rev => rev.x === item.x && rev.y === item.y)
    );
    
    if (currentItem) {
      setMessage('Press P to pick or S to skip item');
    }
  }, [playerPos, items, revealedItems]);

  const handlePlayAgain = () => {
    window.location.reload();
  };

  const handleSkip = () => {
    window.location.href = '/';
  };

  // Add timer effect
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          clearInterval(timer);
          setGameOver(true);
          setMessage('Time\'s up!');
          setShowCompletionDialog(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.gameContainer}>
      <div className={styles.skipButtonContainer}>
        <button 
          onClick={handleSkip}
          className={styles.skipButton}
        >
          Skip Game
        </button>
      </div>
      
      {(gameWon || gameOver) && 
        <div className={gameWon ? styles.winMessage : styles.loseMessage}>
          {gameWon ? '🎉 Congratulations! 🎉' : '😔'}
        </div>
      }
      <div className={styles.topBar}>
        <div className={styles.scoreBoard}>
          Score: {score}
        </div>
        <div className={styles.timer}>
          Time: {timeLeft}s
        </div>
      </div>
      <div className={styles.instructions}>
        <p>Use arrow keys to move</p>
        <p>Press SPACE or P to pick, S to skip items</p>
        <p>Starting score: {INITIAL_SCORE}</p>
        <p>Star: +{STAR_POINTS} points | Poison: -{POISON_PENALTY} points</p>
        <p>Time remaining: {timeLeft} seconds</p>
      </div>
      <div className={styles.maze}>
        {maze.map((row, y) => (
          <div key={y} className={styles.row}>
            {row.map((cell, x) => {
              const item = items.find(i => i.x === x && i.y === y);
              const isRevealed = revealedItems.some(r => r.x === x && r.y === y);
              const isPlayerHere = playerPos.x === x && playerPos.y === y;
              const isActiveItem = isPlayerHere && item && !isRevealed;
              
              return (
                <div 
                  key={`${x}-${y}`} 
                  className={`
                    ${styles.cell} 
                    ${cell === 1 ? styles.wall : ''} 
                    ${cell === 2 ? styles.goal : ''}
                    ${isPlayerHere ? styles.player : ''}
                    ${item && !isRevealed ? `${styles.maskedItem} ${isActiveItem ? styles.activeItem : ''}` : ''}
                    ${item && isRevealed ? (item.type === 'star' ? styles.star : styles.poison) : ''}
                    ${isActiveItem ? styles.activeCell : ''}
                  `}
                />
              );
            })}
          </div>
        ))}
      </div>
      {message && !gameWon && !gameOver && 
        <div className={styles.message}>{message}</div>
      }
      
      {showCompletionDialog && (
        <div className={styles.modalOverlay}>
          <div className={styles.completionDialog}>
            <h2>
              {gameWon ? '🎉 Victory! 🎉' : '😔 Game Over'}
            </h2>
            <p>
              {gameWon 
                ? 'Congratulations!' 
                : score >= 300 
                  ? 'Time\'s up!' 
                  : 'Score of at least 300 needed for leaderboard!'}
            </p>
            <p>Final Score: {score >= 300 ? score - 300 : 0}</p>
            {score >= 300 && <p>Added to leaderboard!</p>}
            <div className={styles.dialogButtons}>
              <button 
                onClick={handlePlayAgain}
                className={styles.dialogButton}
              >
                Play Again
              </button>
              <button 
                onClick={handleSkip}
                className={`${styles.dialogButton} ${styles.cancelButton}`}
              >
                Skip
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MazeGame; 